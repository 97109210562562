import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

import TextBlock from "../components/text-block"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="404: Not found" />
    <TextBlock>
      <h1>404 :-(</h1>
      <p>We couldn't find the information you have requested.</p>
      <p>
        Please continue to our <Link to="/">home page</Link>.
      </p>
    </TextBlock>
  </Layout>
)

export default NotFoundPage
